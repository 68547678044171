.linkbutton {
  background-color: $highlight;
  border-bottom: 5px solid darken($highlight, 10%);
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  padding: $m-size;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: $l-size;
  &:hover {
    background: darken($highlight, 10%)
  }
  @media (max-width: $desktop-breakpoint) {
    padding: $s-size;
    margin: $m-size $xl-size;
    font-size: $m-size;
  }
}

.linkbutton__link {
  text-decoration: none;
  &:visited {
    color: yellow;
  }
}
