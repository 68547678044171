.projects__header {
    background: $off-white;
    justify-content: center;
    display: flex;
    padding: $m-size;
    margin: $l-size 0;
    overflow: hidden;
}

.grid-align {
  display: flex;
  justify-content: center;
  padding-bottom: $m-size;
}

.projects__filter {
  display: flex;
  flex-direction: column;

  @media(min-width: $desktop-breakpoint) {
    flex-direction: row;
  }
}

.projects__overview {
  display: flex;
  justify-content: space-between;

}
