.social-link {
  display:flex;
  align-items: center;
  text-decoration: none;
  color: $off-white;
  margin: $m-size;
  margin-bottom: 0;
}

.social-link__img {
  height: 4rem;
  width: 4rem;
  margin: 5px
}
