@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;1,200&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  color: $dark-grey;
  font-family: 'Nunito', Arial, sans-serif;
  font-size: $m-size;
  line-height: 1.6;
  background: $background;

}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}
