.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $off-white;
  margin-top: $xl-size;
}

.not-found__404 {
  font-size: 10rem;
  color: $highlight;
  margin-bottom: $xl-size;
  font-weight: bold;
}

.not-found__message {
  margin-bottom: $xl-size;
  font-size: $l-size;
  color: $grey;
}
