.bottom {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
}

.footer {
  background: $dark-grey;
  border-top: 2px solid $highlight;
  padding-bottom: 8rem;
  @media (min-width: $desktop-breakpoint) {
    padding-bottom: 0;
  }
}

.footer--center {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey;
}
