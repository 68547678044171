.filter-button {
  font-size: $l-size;
  margin: 0 $m-size;
  background: none;
  border: none;
  font-weight: bold;
  color: $grey;
  outline: none;
  &:hover {
    div {
      height: 3px;
      width: 100%;
      background: $highlight;
      opacity: 0.3;
      margin-top: 0.5rem;
    }
  }
}

.filter-button--active {
  height: 3px;
  width: 100%;
  background: $highlight;
  margin-top: 0.5rem;
}
