.button-section {
  background: $off-white;
  padding: $m-size 0;
}

.button-section__content {
  @media (min-width: $desktop-breakpoint) {
    justify-content: space-around;
    align-items: center;
    display: flex;
    max-width: 80rem;
    margin: 0 auto;
    padding: $m-size;
  }
  margin: $l-size;
  margin-bottom: 0;
}

.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $m-size 0;
}

.about-section__avatar {
  border-radius: 50%;
  height: 20rem;
  width: 20rem;
  object-fit:cover;
  margin-top: $m-size;
}

.about-section__description {
  font-size: 2rem;
  width: 60rem;
  margin: $m-size 2rem;

    @media (max-width: $desktop-breakpoint) {
      width: 32rem;

    }
}

.hero-section--background {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ), url("/images/hero_background.jpg");
  background-size: cover;
  width: 100%;
  height: 95vh;
}

.hero-section__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 2rem;
  height: 100%;
  color: white;
  h1 {
    margin: $s-size 0;
  }
  @media (min-width: $desktop-breakpoint){
    align-items: flex-start;
    height: 100%;
    margin: 0 30rem;
    h1 {
      font-size: $xl-size;
      margin: $s-size 0;
    }
  }
  
}

.hero-section__cta {
  margin: $m-size;
}
