.project-details {
  display: flex;
  flex-direction: column;
}

.project-details__top-image-container {
  background: $off-white;
}

.project-details__top-image {
  max-height: 50rem;
  object-fit: contain;
  margin-top: $m-size;
}

.project-details__headline {
  text-align: center;
  margin-bottom: 0;
}

.project-details__link-container {
  display: flex;
  flex-direction: column;
  margin: 0 $m-size;
  margin-bottom: $l-size;
}

.project-details__link {
  color: $highlight;
}
