.contact-form {
  display:flex;
  flex-direction: column;
  align-items: center;
}

.contact-form__input {
  border: 1px solid #cacccd;
  height: 50px;
  width: 400px;
  margin: $m-size;
  padding: $s-size;
  font-size: $font-size-large;
  font-weight: 300;
  @media (max-width: $desktop-breakpoint) {
    width: 300px;
  }
}

.contact-form__textarea {
  @extend .contact-form__input;
  height: 10rem;
}

.contact-form__submit {
    background-color: $green;
    border:none;
    border-bottom: 5px solid darken($green, 10%);
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    padding: $m-size;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: $m-size;
}
