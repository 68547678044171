//Colors
$green: #27d936;
$dark-grey: #333333;
$grey: #666666;
$light-grey: #eee;
$off-white:  #f7f7f7;
$highlight: #27d936;
$background: #fdfdfd;

//Font size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;

//Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
