.project-content__container {
  display: flex;
  margin: $m-size;
  p {
    margin: 0;
  }
  @media (max-width: $desktop-breakpoint) {
    flex-direction: column;
    margin: $s-size;
  }
}

.project-content--reverse {
  flex-direction: row-reverse;
  @media (max-width: $desktop-breakpoint) {
    flex-direction: column;
  }
}

.project-content__image {
  min-width: 0;
  object-fit: scale-down;
  width: 100%;

  @media (min-width: $desktop-breakpoint) {
    display: flex;
    flex: 1;
    max-width: 50%;
  }
}

.project-content__text {
  display: flex;
  flex-direction: column;
  flex: 1;
}
