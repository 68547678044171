.bottom-navigation {
  display: flex;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background: white;
  height: 10%;
  padding: 5px 0;
  -webkit-box-shadow: 0px 5px 25px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 5px 25px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 5px 25px -2px rgba(0,0,0,0.75);
}
