.project-item {
  border: 1px solid $light-grey;
  border-radius: 5px;
  height: 37rem;
  width: 27rem;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: $grey;
  margin-bottom: $m-size;
  &:hover {
    border: 2px solid $highlight;
  }
}

.project-item__img-container {
  background: $off-white;
  height: 17rem;
}

.project-item__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-item__text-container {
  margin: 0 $s-size;
  overflow: hidden;
}

.project-item__short {
  margin: 0;
}

.project-item__link {
  margin: $m-size;
  margin-bottom: $s-size;
  color: $highlight;
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  padding: 0;
}
