.headline {
  color: $dark-grey;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: $m-size;
}

.headline__underline {
  background: $highlight;
  height: 5px;
  border-radius: 5px;
  margin: 0 2rem;
}

.headline__text {
  margin: 0;
}
